import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IMAGES, SVGICON } from "../constants/theme";
//import Chatbot from 'react-chatbot-kit';
//import 'react-chatbot-kit/build/main.css';
//import config from '../components/Chatbot/config';
//import '../components/Chatbot/Chatbot.css';
//import MessageParser from '../components/Chatbot/MessageParser';
//import ActionProvider from '../components/Chatbot/ActionProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faTimes } from '@fortawesome/free-solid-svg-icons';



const Footer2 = () => {
	let update = new Date();

	// const [showChatbot, setShowChatbot] = useState(false);
	// const handleToggleChatbot = () => {
	// 	setShowChatbot(!showChatbot);
	// };


	return (
		<>
			{/* chatbot code */}
			{/* <div className="chatbot-container">
				<button className="chatbot-toggle-button" onClick={handleToggleChatbot}>
					<FontAwesomeIcon icon={showChatbot ? faTimes : faCommentDots} size="2x" />
				</button>
				{showChatbot && (
					<div className="chatbot-container">
						<Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
					</div>
				)}
			</div> */}
			{/* footer */}
			<footer className="site-footer style-1 footer-action bg-dark">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-xl-4 col-md-12">
								<div className="widget widget_about">
									<div className="footer-logo logo-white">
										

										<Link to={"/"}>
											<img
												className="select_logo_white"
												src={IMAGES.logoWhite2}
												alt=""
											/>
										</Link>
									</div>
									<p>
										Where Every Goal Becomes Reality
									</p>
									<h6 className="m-b15">Our Socials</h6>
									<div className="dz-social-icon style-1">
										<ul>
											<li>
												<Link
													target="_blank"
													to="https://www.facebook.com/keepmefitclub"
													rel="noreferrer"
												>
													<i className="fab fa-facebook-f"></i>
												</Link>
											</li>{" "}
											<li>
												<Link
													target="_blank"
													to="https://www.instagram.com/keepmefitclub"
													rel="noreferrer"
												>
													<i className="fab fa-instagram"></i>
												</Link>
											</li>{" "}
											<li>
												<Link
													target="_blank"
													to="https://wa.me/+447460641250"
													rel="noreferrer"
												>
													<i className="fa-brands fa-whatsapp"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-md-4 wow fadeInUp">
								<div className="widget widget_locations">
									<h4 className="footer-title">Have questions?</h4>
									<div className="clearfix">
										<p>
										<Link to={"mailto:keepmefitclub@keepme.app?subject=Website%20Inquiry"}>
										<i className="fa fa-envelope"></i> Email us
										</Link>
										</p>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-md-4 wow fadeInUp">
								<div className="widget widget_locations">
									<h4 className="footer-title">Head Office</h4>
									<div className="clearfix">
										<h6>London</h6>
										<p>71-75 Shelton Street Covent Garden, London UK, WC2H 9JQ </p>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-4 wow fadeInUp">
								<div className="widget widget_working">
									<h4 className="footer-title">Our Timings</h4>
									<ul>
										<li>
											<span className="days">Open 24 hours</span>
											<span className="time">
												<Link to={"/"}>7 days a week</Link>
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Footer Bottom Part --> */}
				<div className="container">
					<div className="footer-bottom">
						<div className="text-center">
							<span className="copyright-text">
								Copyright © {update.getFullYear()}{" "}
								<Link
									to="/"
									rel="noreferrer"
									target="_blank"
								>
									Keepme Fit Club
								</Link>
							</span>
						</div>
					</div>
				</div>
				<img
					className="svg-shape-1 rotate-360"
					src={IMAGES.footercircle}
					alt=""
				/>
				<img
					className="svg-shape-2 rotate-360"
					src={IMAGES.footercircle}
					alt=""
				/>
			</footer>
		</>
	);
};

export default Footer2;
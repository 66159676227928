import React, { useRef, useState } from "react";
import { IMAGES } from "../constants/theme";
import swal from "sweetalert";
import './AboutMemberships.css';

const memPlans1 = [
	{ images: IMAGES.overlayBoxpic1, title: "Standard", titlesub: "Text" },
	{ images: IMAGES.overlayBoxpic2, title: "Bundle", titlesub: "Text" },
	{ images: IMAGES.overlayBoxpic3, title: "Premium", titlesub: "Text" },
	{ images: IMAGES.overlayBoxpic4, title: "Off-Peak", titlesub: "Text" },
	{ images: IMAGES.overlayBoxpic5, title: "Student/Senior", titlesub: "Text" },
	{ images: IMAGES.overlayBoxpic6, title: "Annual", titlesub: "Text" },
];

const errorStyle = {
	color: 'red',
	fontSize: '0.875em',
	marginTop: '0.25em',
	display: 'block'
};

const inputGroupStyle = {
	marginBottom: '1.5em'
};

const Memberships = () => {
	const formRef = useRef(null);
	const iframeRef = useRef(null);
	const [errors, setErrors] = useState({});

	const validate = (data) => {
		let tempErrors = {};
		if (!data.full_name) tempErrors.full_name = "Name is required.";
		if (!data.email_address) tempErrors.email_address = "Email is required.";
		else if (!/\S+@\S+\.\S+/.test(data.email_address)) tempErrors.email_address = "Email is invalid.";
		if (!data.phone_number) tempErrors.phone_number = "Phone number is required.";

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(formRef.current);
		const data = Object.fromEntries(formData.entries());

		if (!validate(data)) return;

		// Construct query string
		const queryString = new URLSearchParams(data).toString();
		const url = `https://product.keepme.ai/vapi/lead/add?${queryString}`;
		//const url = `http://localhost/keepme-bitbucket/public/vapi/lead/add?${queryString}`;

		// Set the iframe src to the constructed URL
		iframeRef.current.src = url;

		// Handle the success message
		swal({
			title: "Success",
			text: "Our AI assistant Olivia will call you shortly.",
			icon: "success",
			buttons: {
				confirm: {
					text: "Okay",
					value: true,
					visible: true,
					className: "btn-red",
					closeModal: true
				}
			}
		});

	};

	return (
		<>
			<div className="row">

				{/* MAIN TITLE */}
				<div className="col-lg-4 col-sm-6 m-b5 wow fadeInUp">
					<div className="content-box h-100">
						<div className="section-head style-1 m-t30">
							<h2 className="title">
								Book a <span>Tour</span>
							</h2>
							<p className="description m-b30">
								Choose the perfect fit from our flexible membership options.
							</p>
							<p className="p-big m-b25">
								<strong>Have questions?</strong> Use the form below to get in touch!
							</p>
							<form className="dz-form dzForm style-1" style={{ paddingLeft: '0px' }} ref={formRef} onSubmit={handleSubmit}>
								<div className="dzFormMsg"></div>
								<div className="row">
									<div className="col-lg-12">
										<div className="input-group input-line" style={inputGroupStyle}>
											<input
												name="site_type"
												type="hidden"
												value="staging"
											/>
											<input
												name="full_name"
												type="text"
												className="form-control"
												placeholder="Name"
											/>
											{errors.full_name && <span style={errorStyle}>{errors.full_name}</span>}
										</div>
									</div>
									<div className="col-xl-12">
										<div className="input-group input-line" style={inputGroupStyle}>
											<input
												name="email_address"
												type="text"
												className="form-control"
												placeholder="Email"
											/>
											{errors.email_address && <span style={errorStyle}>{errors.email_address}</span>}
										</div>
									</div>
									<div className="col-xl-12">
										<div className="input-group input-line" style={inputGroupStyle}>
											<input
												name="phone_number"
												type="text"
												className="form-control"
												placeholder="Phone"
											/>
											{errors.phone_number && <span style={errorStyle}>{errors.phone_number}</span>}
										</div>
									</div>
									<div className="col-sm-12">
										<button
											name="submit"
											type="submit"
											value="Submit"
											className="btn btn-primary btn-lg btn-skew"
										>
											<span>Send Message</span>
										</button>
									</div>
								</div>
							</form>

							<div className="d-lg-block position-relative wow fadeInUp" style={{ bottom: '-200px' }}>
								<h2 className="bg-data-text style-3" style={{ bottom: '30%', fontSize: '130px' }}>
									<span>P</span>
									<span>L</span>
									<span>A</span>
									<span>N</span>
									<span>S</span>
								</h2>
							</div>
						</div>
					</div>
				</div>

				{/* PLAN 1-6 */}
				<div className="col-lg-8 col-sm-6 align-self-center m-b5 wow fadeInUp">
					<div className="row">
						{memPlans1.map((item, index) => (
							<div className="col-lg-6 col-sm-6 m-b5" key={index}>
								<div className="dz-team style-1" style={{ marginBottom: '5px' }}>
									<div className="dz-media" style={{ borderRight: '0px' }}>
										<img src={item.images} alt="" />
									</div>
									<div className="dz-content" style={{ transform: 'none', transform: 'skewX(-12deg)' }}>
										<h5 className="dz-name" style={{ padding: '10px', fontSize: '18px', bottom: '30px', width: '80%', left: '10px' }}>
											{item.title}
										</h5>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{/* Hidden iframe for submitting the form */}
			<iframe ref={iframeRef} style={{ display: 'none' }} />
		</>
	);
};

export default Memberships;
